import plusCircle from '@luna-protocol/core/src/assets/plus-circle.svg';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak.tsx';
import { DealInformation, KVPair } from '@luna-protocol/core/src/types.ts';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import config from '@luna-protocol/core/src/utils/feature-flags.json';
import { ChangeEvent, useContext } from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../AppContext.tsx';
import FDRow from './FDRow.tsx';
import messages from './FeesDeductions.messages.ts';
import './FeesDeductions.scss';

const FeesDeductions = () => {
  const { formatMessage } = useIntl();
  const { deal, setDeal, OEMType, dealerFlowTemplate } = useContext(AppContext);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updateProducts = (products: KVPair[], key: string, value: string) => {
      return products?.map(product => {
        if (product?.key === key) {
          if (e.target.id === 'provider') {
            return { ...product, name: value };
          }
          return { ...product, value: parseFloat(!value || isNaN(Number(value)) ? '0' : value) };
        }
        return product;
      });
    };
    const updatedBackendProducts = deal && updateProducts(deal?.backend_products || [], name, value);
    const updatedAdditions = deal && updateProducts(deal?.additions || [], name, value);
    const updatedDeductions = deal && updateProducts(deal?.deductions || [], name, value);

    const updatedDeal = {
      ...deal,
      additions: updatedAdditions,
      backend_products: updatedBackendProducts,
      deductions: updatedDeductions,
    } as DealInformation;

    setDeal(updatedDeal as DealInformation);
  };

  const changeTerm = (term: number, product: KVPair) => {
    const updatedBackendProducts = deal?.backend_products?.map(backendProduct => {
      if (backendProduct.key === product.key) {
        return { ...backendProduct, term };
      }
      return backendProduct;
    });

    const updatedDeal = {
      ...deal,
      backend_products: updatedBackendProducts,
    } as DealInformation;

    setDeal(updatedDeal as DealInformation);
  };

  const addOther = () => {
    const updatedBackendProducts = deal?.backend_products
      ? [
          ...deal.backend_products,
          {
            key: `Other-New Product`,
            value: 0,
          },
        ]
      : [{ key: `Other-New Product`, value: 0 }];

    const updatedDeal = {
      ...deal,
      backend_products: updatedBackendProducts,
    } as DealInformation;

    setDeal(updatedDeal as DealInformation);
  };

  function getSubtext(product: KVPair): string {
    switch (product?.key) {
      case 'License Fees':
      case 'Title Fees':
        return formatMessage(messages.publicOfficialsSubtext);
      case 'Document Prep Fees':
        return formatMessage(messages.conditionalStateSubtext);
      case 'FL Doc Stamp Tax':
        return formatMessage(messages.floridaSubtext);
    }

    return '';
  }

  function getFLStamp() {
    // if the customer has not opted for the FL Doc Stamp Tax, it is 0.0035 of the total requested
    // if the customer has opted for the FL Doc Stamp Tax, it is 0.0035 of the total requested minus the FL Doc Stamp Tax
    const fl = deal?.additions?.find(product => product?.key === 'FL Doc Stamp Tax' && product?.value);
    let tax = 0;
    if (fl) {
      tax = ((deal?.totals?.total_requested || 0) - (fl.value || 0)) * 0.0035;
    } else {
      tax = (deal?.totals?.total_requested || 0) * 0.0035;
    }
    tax = Math.round(tax * 100) / 100;
    return tax.toFixed(2);
  }

  return (
    <div className="fees_and_deductions--container">
      <div className="fees_and_deductions--row-title">
        <h4>{formatMessage(messages.feesTitle)}</h4>
      </div>
      {deal?.additions?.map((product, index) => (
        <FDRow
          key={`additions-${index}`}
          product={product}
          onChange={onChange}
          checkbox
          hardValue={product?.key === 'FL Doc Stamp Tax' ? getFLStamp() : undefined}
          subtext={getSubtext(product)}
        />
      ))}
      <div className="fees_and_deductions--row title-row">
        <div className="fees_and_deductions--row-title">
          <h4>{formatMessage(messages.feesTotalTitle)}</h4>
        </div>
        <div className="fees_and_deductions--row-value margin-left">
          <h4>{USDollar.format(deal?.totals?.additions || 0)}</h4>
        </div>
      </div>
      <LineBreak largeMargin bordered />

      <div className="fees_and_deductions--row">
        <div className="fees_and_deductions--row-title">
          <h4>{formatMessage(messages.bepTitle)}</h4>
        </div>
      </div>
      {deal?.backend_products?.map((product, index) =>
        !product?.key.startsWith('Other') ? (
          <>
            {/* All Backend Products must take the name of the provider as an input (requireName) which are external to
              the seller. GAP Waiver is the only Backend Product that is paid to the Seller*/}
            <FDRow
              key={`backend_products-${index}`}
              product={product}
              onChange={onChange}
              checkbox
              requireName={product.key != 'GAP Waiver'}
              termField
              onChangeTerm={term => {
                changeTerm(term, product);
              }}
              term={product.term}
            />

            {config.FF_OEMTemplatesEnabled &&
              dealerFlowTemplate?.warranty_link &&
              product.key.startsWith('Service Contract') && (
                <a
                  className="warranty-link"
                  href={dealerFlowTemplate?.warranty_link}
                  target={'_blank'}
                  rel="noopener noreferrer">
                  {dealerFlowTemplate?.warranty_text}
                </a>
              )}

            {!config.FF_OEMTemplatesEnabled &&
              OEMType === 'brunswick' &&
              product.key.startsWith('Service Contract') && (
                <a
                  className="warranty-link"
                  href={'https://dealer.passport-premier.com/login'}
                  target={'_blank'}
                  rel="noopener noreferrer">
                  {formatMessage(messages.warrantyLink)}
                </a>
              )}
          </>
        ) : (
          <>
            {!product?.key.startsWith('Other-') ? (
              <>
                <LineBreak />
                <div className="fees_and_deductions--row">
                  <div className="fees_and_deductions--row-title">{product?.key}</div>
                </div>
                <div className={'fees_and_deductions--add'} onClick={addOther}>
                  <img src={plusCircle} alt="Add another product" />
                  {formatMessage(messages.addAnother)}
                </div>
              </>
            ) : (
              <FDRow
                key={`backend_products-${index}`}
                product={product}
                onChange={onChange}
                checkbox
                editable={true}
                requireName
                termField
                onChangeTerm={term => {
                  changeTerm(term, product);
                }}
              />
            )}
          </>
        ),
      )}

      <LineBreak largeMargin={true} />
      <div className="fees_and_deductions--row">
        <div className="fees_and_deductions--row-title">
          <h4>{formatMessage(messages.bepTotalTitle)}</h4>
        </div>
        <div className="fees_and_deductions--row-value margin-left">
          <h4>{USDollar.format(deal?.totals?.backend_products || 0)}</h4>
        </div>
      </div>
      <LineBreak largeMargin bordered />

      <div className="fees_and_deductions--row">
        <div className="fees_and_deductions--row-title">
          <h4>{formatMessage(messages.deductionsTitle)}</h4>
        </div>
      </div>

      {deal?.deductions?.map((product, index) => (
        <FDRow
          key={`deductions-${index}`}
          product={product}
          onChange={onChange}
          checkbox
          lienHolderName={product.key === 'Trade-in value'}
        />
      ))}
      <div className="fees_and_deductions--row">
        <div className="fees_and_deductions--row-title">
          <h4>{formatMessage(messages.deductionsTotalTitle)}</h4>
        </div>
        <div className="fees_and_deductions--row-value margin-left">
          <h4>{USDollar.format(deal?.totals?.deductions || 0)}</h4>
        </div>
      </div>
    </div>
  );
};

export default FeesDeductions;
