import binIcon from '@luna-protocol/core/src/assets/binIcon.png';
import plusCircle from '@luna-protocol/core/src/assets/plus-circle.svg';
import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import Checkbox from '@luna-protocol/core/src/components/Checkbox/Checkbox';
import Input from '@luna-protocol/core/src/components/Input/Input';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import RadioGroup from '@luna-protocol/core/src/components/RadioGroup/RadioGroup.tsx';
import SelectInput from '@luna-protocol/core/src/components/SelectInput/SelectInput.tsx';
import { OEMType as EOEMType, SelectOption } from '@luna-protocol/core/src/types.ts';
import { capitaliseString } from '@luna-protocol/core/src/utils/constants/format.ts';
import config from '@luna-protocol/core/src/utils/feature-flags.json';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';
import useToast from '@luna-protocol/core/src/utils/useToast.ts';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import { ChangeEvent, Fragment, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext.tsx';
import { useGetDealer } from '../../queries/useGetDealerInfo.ts';
import { useGetMakeModels } from '../../queries/useGetMakeModels.ts';
import { ProductType, ProductTypesRes, useGetProductTypes } from '../../queries/useGetProductTypes.ts';
import { usePostApplicationLink } from '../../queries/usePostApplicationLink.ts';
import { getConsumerApp } from '../../utils/getConsumerApp.ts';
import { useDealerID } from '../../utils/useDealerID.ts';
import messages from './NewLead.messages.ts';
import './NewLead.scss';
import { newLeadSchema } from './NewLeadSchema.ts';

interface NewLeadFormValues {
  email_address: string;
  collateral: CollateralInformation[];
  paperApplication: string | null;
}

interface CollateralInformation {
  nada_msrp_invoice: string | number | undefined;
  make?: SelectOption;
  model?: SelectOption;
  title?: string;
  name?: string;
  serial_number: string;
  asset_class?: SelectOption;
  selling_price: number;
  condition?: SelectOption;
  year?: number;
  colour?: string;
}

const RetrievePrequalification = () => {
  useAuth();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const dealerId = useDealerID();
  const { data: productTypes } = useGetProductTypes('TX');
  const { data: makeModel } = useGetMakeModels();
  const [showAddTrailer, setShowAddTrailer] = useState(true);
  const [engineCount, setEngineCount] = useState(0);
  const { data: dealerDetails } = useGetDealer(dealerId as string);
  const [pending, setPending] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);

  const { send } = usePostApplicationLink();
  const { OEMType, dealerFlowTemplate } = useContext(AppContext);

  const { createToast } = useToast();

  const showEngineTrailer =
    (config.FF_OEMTemplatesEnabled && dealerFlowTemplate?.base_type === 'marine') ||
    (!config.FF_OEMTemplatesEnabled && OEMType !== 'ktm' && OEMType !== 'ezgo');

  const initialValues: NewLeadFormValues = {
    email_address: '',
    collateral: [
      {
        serial_number: '',
        selling_price: 0,
        year: new Date().getFullYear(),
        nada_msrp_invoice: 0,
        name: '',
        title: config.FF_OEMTemplatesEnabled
          ? dealerFlowTemplate?.unit_identifier
          : OEMType === EOEMType.KTM
            ? 'VIN'
            : OEMType === EOEMType.EZGO
              ? 'Serial Number'
              : 'HIN',
      },
    ],
    paperApplication: null,
  };

  function getTypeFromClass(value?: string) {
    if (value === undefined) {
      return 'hull-id';
    }
    // map to full data
    const pt = productTypes?.product_type.find((productType: ProductType) => {
      if (productType.productType === value) {
        return productType;
      }
    });
    if (pt === undefined) {
      return 'hull-id';
    }

    if (pt.productCode === 'MARINE' && value?.toLowerCase().includes('trailer')) {
      return 'trailer';
    }
    if (pt.productCode === 'MARINE' && value?.toLowerCase().endsWith('motor')) {
      return 'engine';
    }
    switch (pt.productCode) {
      case 'RECVEHICLE':
        return 'rv';
      case 'POWERSPORT':
        return 'power-sports';
      default:
        return 'hull-id';
    }
  }

  const getLink = (values: NewLeadFormValues, sendButton?: boolean) => {
    const serialData = values.collateral.map(c => {
      return {
        price: Number(c.selling_price),
        serial_number: c.serial_number,
        serial_number_type: getTypeFromClass(c.asset_class?.value),
        serial_number_asset_class: c.asset_class?.value,
        make: c.make?.value || c.name,
        model: c.model?.value,
        year: c.year,
        condition: c.condition?.value,
        nada_msrp_invoice: Number(c.nada_msrp_invoice),
      };
    });

    return `${getConsumerApp()}/application?dealer_id=${dealerId}&is_dealer_device=true&serial_number=${encodeURIComponent(
      JSON.stringify(serialData),
    )}&email=${encodeURIComponent(values.email_address)}&paper_application=${!sendButton ? values.paperApplication : 'yes'}&new_lead=true&dealer_state=${dealerDetails?.address.state}`;
  };

  const emailLink = (values: NewLeadFormValues) => {
    setPending(true);
    send(
      {
        customer_email: values.email_address,
        application_link: getLink(values, true),
      },
      {
        onSuccess: () => {
          createToast({
            title: `${formatMessage(messages.successMessage)}`,
            status: 'success',
          });
          setPending(false);
          navigate('/prequalification');
        },
        onError: () => {
          setPending(false);
          createToast({
            title: `${formatMessage(messages.errorMessage)}`,
            status: 'error',
          });
        },
      },
    );
  };

  function makeAssetClassOptions(data: ProductTypesRes | undefined) {
    const opts: SelectOption[] = [];

    if (config.FF_OEMTemplatesEnabled && dealerFlowTemplate && !data) {
      if (dealerFlowTemplate.base_type) {
        return [
          {
            value: dealerFlowTemplate.base_type,
            label: capitaliseString(dealerFlowTemplate.base_type),
          },
        ];
      }
      if (OEMType === 'ezgo') {
        return [
          {
            value: 'golf-cart',
            label: 'Golf Cart',
          },
        ];
      }
      return [];
    }

    if (!data) {
      if (OEMType === 'ktm') {
        return [
          {
            value: 'motorcycle',
            label: 'Motorcycle',
          },
        ];
      }
      if (OEMType === 'ezgo') {
        return [
          {
            value: 'golf-cart',
            label: 'Golf Cart',
          },
        ];
      }
      return [];
    }

    data.product_type.forEach((productType: ProductType) => {
      opts.push({
        value: productType.productType,
        label: productType.productType,
      });
    });
    return opts;
  }

  const returnMakeModelDropdown = (
    values: NewLeadFormValues,
    index: number,
    setFieldValue: (field: string, value: string) => void,
    touched?: FormikTouched<NewLeadFormValues>,
    errors?: FormikErrors<NewLeadFormValues>,
  ) => {
    let filterType = ''
    switch(OEMType){
      case EOEMType.Brunswick:
        filterType = 'brunswick'
        break;
      case EOEMType.KTM:
        filterType = 'ktm'
        break;
      case EOEMType.EZGO:
        filterType = 'ezgo'
        break;
      case EOEMType.CUSTOM:
        filterType = 'custom'
    }
    if (config.FF_OEMTemplatesEnabled && dealerFlowTemplate) {
      filterType = dealerFlowTemplate.name
    }

    return (
      <>
        <SelectInput
          label={formatMessage(messages.productInformation.fields.make)}
          placeholder={formatMessage(messages.productInformation.fields.make)}
          name={`collateral[${index}].make`}
          onChange={() => {
            if (OEMType === 'ezgo' && values.collateral[index].condition?.value === 'new') {
              const match = makeModel?.make_models.find(
                m =>
                  m.make.toLowerCase() === values.collateral[index].make?.value.toLowerCase() &&
                  m.model.toLowerCase() === values.collateral[index].model?.value.toLowerCase() &&
                  m.year === values.collateral[index].year,
              );

              if (match !== undefined) {
                setFieldValue(`collateral[${index}].nada_msrp_invoice`, `${match.msrp}`);
              }
            }
          }}
          value={values.collateral[index].make}
          options={makeModel?.make_models
            .filter(m => m.oemName.toLowerCase() === filterType.toLowerCase())
            .filter(m => {
              if (values.collateral[index].model) {
                return m.model === values.collateral[index].model?.value;
              }
              return true;
            })
            .filter((v, i, a) => a.findIndex(t => t.make === v.make) === i)
            .map(m => ({
              value: m.make,
              label: m.make,
            }))}
          isClearable
          allowCreate
          error={
            //@ts-expect-error TS2339
            errors?.collateral?.[index]?.make && touched?.collateral?.[index]?.make
              ? //@ts-expect-error TS2339
                errors?.collateral[index]?.make
              : undefined
          }
        />
        <SelectInput
          label={formatMessage(messages.productInformation.fields.model)}
          placeholder={formatMessage(messages.productInformation.fields.model)}
          name={`collateral[${index}].model`}
          onChange={() => {
            if (OEMType === 'ezgo' && values.collateral[index].condition?.value === 'new') {
              const match = makeModel?.make_models.find(
                m =>
                  m.make.toLowerCase() === values.collateral[index].make?.value.toLowerCase() &&
                  m.model.toLowerCase() === values.collateral[index].model?.value.toLowerCase() &&
                  m.year === values.collateral[index].year,
              );

              if (match !== undefined) {
                setFieldValue(`collateral[${index}].nada_msrp_invoice`, `${match.msrp}`);
              }
            }
          }}
          value={values.collateral[index].model}
          options={makeModel?.make_models
            .filter(m => m.oemName.toLowerCase() === filterType.toLowerCase())
            .filter(m => {
              if (values.collateral[index].make) {
                return m.make === values.collateral[index].make?.value;
              }
              return true;
            })
            .map(m => ({
              value: m.model,
              label: m.model,
            }))}
          allowCreate
          isClearable
          error={
            //@ts-expect-error TS2339
            errors?.collateral?.[index]?.model && !!touched?.collateral?.[index]?.model
              ? //@ts-expect-error TS2339
                errors?.collateral?.[index]?.model
              : undefined
          }
        />
      </>
    );
  };

  if (productTypes === undefined || makeModel === undefined || dealerDetails === undefined) {
    return null;
  }

  return (
    <>
      <BackButton path="/prequalification" inverted />
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        <Formik
          initialValues={initialValues}
          validationSchema={newLeadSchema}
          enableReinitialize
          validateOnMount
          onSubmit={values => {
            window.open(getLink(values), '_blank', 'noreferrer');
            createToast({
              title: `${formatMessage(messages.successMessage)}`,
              status: 'success',
            });
            navigate('/prequalification');
          }}>
          {({ values, isValid, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <fieldset className="fieldset">
                  <>
                    <h3>{formatMessage(messages.productInformation)}</h3>
                    <LineBreak bordered />
                    {values.collateral.map((_, index) => {
                      let title = _.title;
                      if (title?.includes('Engine')) {
                        title = formatMessage(messages.engine);
                      }
                      if (title?.includes('Trailer')) {
                        title = formatMessage(messages.trailer);
                      }
                      const isHIN = values.collateral[index].title === 'HIN';
                      const isVIN = values.collateral[index].title === 'VIN';
                      return (
                        <Fragment key={index}>
                          {index > 0 && (
                            <button
                              aria-label="remove"
                              className="icon-button"
                              type="button"
                              onClick={() => {
                                const updatedCollateral = values.collateral.filter((_, i) => i !== index);
                                setFieldValue('collateral', updatedCollateral);
                                if (title?.includes('Engine')) {
                                  setEngineCount(engineCount - 1);
                                }
                                if (title?.includes('Trailer')) {
                                  setShowAddTrailer(true);
                                }
                              }}>
                              <img src={binIcon} />
                            </button>
                          )}
                          <LineBreak />
                          {returnMakeModelDropdown(values, index, setFieldValue, touched, errors)}
                          <Input
                            label={
                              isHIN
                                ? formatMessage(messages.productInformation.fields.serial_number_HIN)
                                : isVIN
                                  ? formatMessage(messages.productInformation.fields.serial_number_VIN)
                                  : formatMessage(messages.productInformation.fields.serial_number)
                            }
                            placeholder={
                              isHIN
                                ? formatMessage(messages.productInformation.fields.serial_number_HIN)
                                : isVIN
                                  ? formatMessage(messages.productInformation.fields.serial_number_VIN)
                                  : formatMessage(messages.productInformation.fields.serial_number)
                            }
                            name={`collateral[${index}].serial_number`}
                            onChange={handleChange}
                            value={values.collateral[index].serial_number}
                            error={
                              //@ts-expect-error TS2339
                              errors?.collateral?.[index]?.serial_number &&
                              !!touched?.collateral?.[index]?.serial_number
                                ? //@ts-expect-error TS2339
                                  errors?.collateral?.[index]?.serial_number
                                : undefined
                            }
                          />
                          <SelectInput
                            label={formatMessage(messages.productInformation.fields.asset_class)}
                            placeholder={formatMessage(messages.productInformation.fields.asset_class)}
                            name={`collateral[${index}].asset_class`}
                            onChange={handleChange}
                            value={values.collateral[index].asset_class}
                            error={
                              //@ts-expect-error TS2339
                              errors?.collateral?.[index]?.asset_class && !!touched?.collateral?.[index]?.asset_class
                                ? //@ts-expect-error TS2339
                                  errors?.collateral?.[index]?.asset_class
                                : undefined
                            }
                            options={makeAssetClassOptions(productTypes)}
                          />
                          <SelectInput
                            label={formatMessage(messages.productInformation.fields.condition)}
                            placeholder={formatMessage(messages.productInformation.fields.condition)}
                            name={`collateral[${index}].condition`}
                            testId={`collateral-${index}-condition`}
                            onChange={e => {
                              if (OEMType === 'ezgo' && e === 'new') {
                                const match = makeModel?.make_models.find(
                                  m =>
                                    m.make.toLowerCase() === values.collateral[index].make?.value.toLowerCase() &&
                                    m.model.toLowerCase() === values.collateral[index].model?.value.toLowerCase() &&
                                    m.year === values.collateral[index].year,
                                );

                                if (match !== undefined) {
                                  setFieldValue(`collateral[${index}].nada_msrp_invoice`, `${match.msrp}`);
                                }
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.collateral[index].condition}
                            error={
                              //@ts-expect-error TS2339
                              errors?.collateral?.[index]?.condition && !!touched?.collateral?.[index]?.condition
                                ? //@ts-expect-error TS2339
                                  errors?.collateral?.[index]?.condition
                                : undefined
                            }
                            options={[
                              {
                                value: 'new',
                                label: 'New',
                              },
                              {
                                value: 'used',
                                label: 'Used',
                              },
                            ]}
                          />
                          <Input
                            type="number"
                            label={formatMessage(messages.productInformation.fields.year)}
                            placeholder={formatMessage(messages.productInformation.fields.year)}
                            name={`collateral[${index}].year`}
                            onChange={e => {
                              if (OEMType === 'ezgo' && values.collateral[index].condition?.value === 'new') {
                                const match = makeModel?.make_models.find(
                                  m =>
                                    m.make.toLowerCase() === values.collateral[index].make?.value.toLowerCase() &&
                                    m.model.toLowerCase() === values.collateral[index].model?.value.toLowerCase() &&
                                    m.year === values.collateral[index].year,
                                );

                                if (match !== undefined) {
                                  setFieldValue(`collateral[${index}].nada_msrp_invoice`, `${match.msrp}`);
                                }
                              }
                              handleChange(e);
                            }}
                            value={values.collateral[index].year}
                            error={
                              //@ts-expect-error TS2339
                              errors?.collateral?.[index]?.year && !!touched?.collateral?.[index]?.year
                                ? //@ts-expect-error TS2339
                                  errors?.collateral?.[index]?.year
                                : undefined
                            }
                          />
                          <>
                            <Input
                              label={
                                config.FF_OEMTemplatesEnabled
                                  ? `${dealerFlowTemplate?.seller_purchase_price_type}/NADA`
                                  : OEMType === EOEMType.KTM
                                    ? formatMessage(messages.productInformation.fields.colour.ktm)
                                    : OEMType === EOEMType.EZGO
                                      ? formatMessage(messages.productInformation.fields.colour.ezgo)
                                      : formatMessage(messages.productInformation.fields.colour.brunswick)
                              }
                              type="currency"
                              currency
                              name={`collateral[${index}].nada_msrp_invoice`}
                              onChange={handleChange}
                              value={values.collateral[index].nada_msrp_invoice}
                              error={
                                //@ts-expect-error TS2339
                                errors?.collateral?.[index]?.nada_msrp_invoice &&
                                !!touched?.collateral?.[index]?.nada_msrp_invoice
                                  ? //@ts-expect-error TS2339
                                    errors?.collateral?.[index]?.nada_msrp_invoice
                                  : undefined
                              }
                              disabled={
                                OEMType === 'ezgo' &&
                                makeModel?.make_models.find(
                                  m =>
                                    m.make.toLowerCase() === values.collateral[index].make?.value.toLowerCase() &&
                                    m.model.toLowerCase() === values.collateral[index].model?.value.toLowerCase() &&
                                    m.year === values.collateral[index].year,
                                ) !== undefined
                              }
                            />
                          </>
                          <Input
                            type="currency"
                            label={formatMessage(messages.productInformation.fields.selling_price)}
                            placeholder={formatMessage(messages.productInformation.fields.selling_price)}
                            name={`collateral[${index}].selling_price`}
                            onChange={handleChange}
                            onClick={(e: ChangeEvent<HTMLInputElement>) => e.target.select()}
                            value={values.collateral[index].selling_price}
                            error={
                              //@ts-expect-error TS2339
                              errors?.collateral?.[index]?.selling_price &&
                              !!touched?.collateral?.[index]?.selling_price
                                ? //@ts-expect-error TS2339
                                  errors?.collateral?.[index]?.selling_price
                                : undefined
                            }
                            currency
                          />
                          <LineBreak bordered />
                        </Fragment>
                      );
                    })}
                    {showEngineTrailer && (
                      <>
                        <fieldset className="fieldset fullWidth">
                          {values.collateral.length < 4 && (
                            <div className="add-accessory-button-group">
                              <>
                                {showAddTrailer && (
                                  <button
                                    className="icon-button"
                                    type="button"
                                    onClick={() => {
                                      const newCollateral: CollateralInformation = {
                                        name: '',
                                        serial_number: '',
                                        selling_price: 0,
                                        title: 'Trailer',
                                        nada_msrp_invoice: 0,
                                      };
                                      setFieldValue('collateral', [...values.collateral, newCollateral]);
                                      setShowAddTrailer(false);
                                    }}>
                                    <img src={plusCircle} />
                                    <span>{formatMessage(messages.productInformation.fields.addTrailer)}</span>
                                  </button>
                                )}
                                {engineCount < 2 && (
                                  <button
                                    className="icon-button"
                                    type="button"
                                    onClick={() => {
                                      const newCollateral: CollateralInformation = {
                                        name: '',
                                        serial_number: '',
                                        selling_price: 0,
                                        title: `Engine ${engineCount + 1}`,
                                        nada_msrp_invoice: undefined,
                                      };
                                      setFieldValue('collateral', [...values.collateral, newCollateral]);
                                      setEngineCount(engineCount + 1);
                                    }}>
                                    <img src={plusCircle} />
                                    <span>{formatMessage(messages.productInformation.fields.addEngine)}</span>
                                  </button>
                                )}
                              </>
                            </div>
                          )}
                        </fieldset>
                        <LineBreak bordered />
                      </>
                    )}
                    {/* {values.collateral.length < 4 && } */}
                  </>
                </fieldset>
                <fieldset className="fieldset fullWidth">
                  <>
                    <h3>{formatMessage(messages.customerInformation)}</h3>
                    <Input
                      label={formatMessage(messages.emailLabel)}
                      placeholder={formatMessage(messages.emailPlaceholder)}
                      name="email_address"
                      onChange={handleChange}
                      value={values.email_address}
                      error={errors.email_address}
                      onBlur={handleBlur}
                    />
                    <LineBreak />
                    <Checkbox
                      data-testid="emailConfirmation"
                      label={formatMessage(messages.emailConfirmation)}
                      value={emailConfirmed}
                      onChange={() => setEmailConfirmed(!emailConfirmed)}
                      name="emailConfirmation"
                    />
                  </>
                </fieldset>
                <fieldset className="fieldset">
                  <h3>{formatMessage(messages.paperApplicationTitle)}</h3>
                  <p>{formatMessage(messages.paperApplicationDescription)}</p>
                  <RadioGroup
                    name="paperApplication"
                    buttons={[
                      {
                        label: 'Yes',
                        value: 'yes',
                      },
                      {
                        label: 'No',
                        value: 'no',
                      },
                    ]}
                  />
                </fieldset>
                <fieldset className="fieldset mt-3">
                  <Button
                    variant="secondary"
                    fullWidth={false}
                    width={217}
                    testId="submit-newLead-email"
                    disabled={!isValid || !emailConfirmed || (isValid && emailConfirmed && pending)}
                    pending={pending}
                    onClick={() => emailLink(values)}>
                    {formatMessage(messages.submitEmail)}
                  </Button>
                  <Button
                    variant="secondary"
                    fullWidth={false}
                    type="submit"
                    width={217}
                    testId="submit-newLead-continue"
                    disabled={!isValid || !emailConfirmed || (isValid && emailConfirmed && pending)}>
                    {formatMessage(messages.submitInFlow)}
                  </Button>
                </fieldset>
              </Form>
            );
          }}
        </Formik>
      </Body>
    </>
  );
};

export default RetrievePrequalification;
