import { Formik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, useNavigate } from 'react-router-dom';
import { UseMutateFunction } from 'react-query';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import Input from '@luna-protocol/core/src/components/Input/Input';
import useRemoveSessionOnLeave from '@luna-protocol/core/src/utils/useRemoveSessionOnLeave.ts';

import { PasswordResetRequest } from '../../types.ts';
import messages from './PasswordReset.messages.ts';
import './PasswordReset.scss';
import passwordResetSchema from './PasswordResetSchema.ts';

export type ResetCredentials = {
  password: string;
  passwordConfirm: string;
};

export type PasswordResetProps = {
  patchPassword: () => { patchPassword: UseMutateFunction<unknown, unknown, PasswordResetRequest, unknown> };
};

const PasswordReset = ({ patchPassword: patchPasswordHook }: PasswordResetProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { patchPassword } = patchPasswordHook();
  const [serverError, setServerError] = useState({
    message: '',
  });
  const [pending, setPending] = useState(false);
  const { resetSessionRemoval } = useRemoveSessionOnLeave();

  const initialValues: ResetCredentials = {
    password: '',
    passwordConfirm: '',
  };

  const onSubmit = (values: ResetCredentials) => {
    setPending(true);
    const { password } = values;

    patchPassword(
      { new_password: password },
      {
        onSuccess: () => {
          resetSessionRemoval();
          navigate('/login');
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (context: any) => {
          setPending(false);
          console.error('error', context);
          setServerError(context);
        },
      },
    );
  };

  return (
    <>
      <BackButton path="/login" inverted />
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          onChange={() => setServerError({ message: '' })}
          validationSchema={passwordResetSchema}
          validateOnMount={true}
          validateOnChange>
          {({ values, errors, handleBlur, handleSubmit, handleChange }) => (
            <Form>
              <Input
                label={formatMessage(messages.passwordLabel)}
                placeholder={formatMessage(messages.passwordLabel)}
                name="password"
                onChange={handleChange}
                value={values.password}
                error={undefined}
                onBlur={handleBlur}
                type="password"
              />
              <Input
                label={formatMessage(messages.passwordConfirmLabel)}
                placeholder={formatMessage(messages.passwordLabel)}
                name="passwordConfirm"
                onChange={handleChange}
                value={values.passwordConfirm}
                error={undefined}
                onBlur={handleBlur}
                type="password"
              />
              {Object.values(errors).length > 0 && (
                <div className="error-list">
                  <p>{formatMessage(messages.errorListTitle)}</p>
                  <ul>
                    {Object.values(errors).map((error, i) => (
                      <li key={i}>{error}</li>
                    ))}
                    <li>{formatMessage(messages.errorListComparison)}</li>
                    <li>{formatMessage(messages.errorListReuse)}</li>
                  </ul>
                </div>
              )}
              {serverError.message && <p className="error-list">{serverError.message}</p>}
              <Button type="submit" onClick={handleSubmit} pending={pending} testId="passwordReset-submit">
                {formatMessage(messages.createPasswordLabel)}
              </Button>
            </Form>
          )}
        </Formik>
      </Body>
    </>
  );
};

export default PasswordReset;
