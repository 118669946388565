import { ChangeEvent, useEffect, useState } from 'react';

import './FeesDeductions.scss';
import { KVPair } from '@luna-protocol/core/src/types';

type FDRowProps = {
  key: string;
  product: KVPair;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checkbox?: boolean;
  editable?: boolean;
  requireName?: boolean;
  lienHolderName?: boolean;
  subtext?: string;
  hardValue?: string;
  termField?: boolean;
  onChangeTerm?: (e: number) => void;
  term?: number;
};

const FDRow = ({
  key,
  product,
  onChange,
  checkbox,
  editable,
  requireName = false,
  lienHolderName = false,
  subtext = '',
  hardValue = undefined,
  termField = false,
  term = undefined,
  onChangeTerm,
}: FDRowProps) => {
  const [enabled, setEnabled] = useState(!!product.value);
  const [termValue, setTerm] = useState(0 || term);

  useEffect(() => {
    if (onChangeTerm) {
      onChangeTerm(termValue as number);
    }
  }, [termValue]);

  const toggleEnabled = () => {
    if (!enabled) {
      onChange({ target: { name: product?.key, value: '0' } } as ChangeEvent<HTMLInputElement>);
    } else if (enabled && hardValue) {
      onChange({ target: { name: product?.key, value: (hardValue || 0).toString() } } as ChangeEvent<HTMLInputElement>);
    }
    setEnabled(!enabled);
  };

  useEffect(() => {
    if (!enabled) {
      onChange({ target: { name: product?.key, value: '0' } } as ChangeEvent<HTMLInputElement>);
    } else if (enabled && hardValue) {
      onChange({ target: { name: product?.key, value: (hardValue || 0).toString() } } as ChangeEvent<HTMLInputElement>);
    }
  }, [enabled, hardValue]);

  const changeTerm = (e: ChangeEvent<HTMLInputElement>) => {
    setTerm(Number(e.target.value));
  };

  const onEditKey = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    product.key = `Other-${value}`;
    onChange({ target: { name, value } } as ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <div key={key} className="fees_and_deductions--row">
        {checkbox && (
          <input
            className="checkbox-input"
            type="checkbox"
            name={product?.key}
            checked={enabled}
            onChange={toggleEnabled}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
              const target = e.target as HTMLInputElement;
              target.select();
            }}
          />
        )}
        <div className="fees_and_deductions--row-title">
          {editable ? (
            <input
              className="text-input"
              type="text"
              placeholder="Other"
              name={product?.key.replace('Other-', '')}
              onChange={e => onEditKey(e)}
              disabled={!enabled}
              value={enabled ? product?.key.replace('Other-', '') : ''}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
            />
          ) : (
            product?.key
          )}
        </div>
        {requireName && (
          <div className="fees_and_deductions--row-provider margin-left">
            <input
              className="text-input"
              type="text"
              id="provider"
              placeholder="Provider Name"
              name={product?.key}
              onChange={e => onChange(e)}
              disabled={!enabled}
              value={enabled ? product?.name : ''}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
            />
          </div>
        )}
        {termField && (
          <div className={`fees_and_deductions--row-value-term ${!requireName ? 'margin-left' : null}`}>
            <input
              className="text-input"
              type="number"
              placeholder="Term (months)"
              name={product?.key}
              onChange={e => {
                if (Number(e.target.value) >= 0) {
                  return changeTerm(e);
                }
              }}
              disabled={!enabled}
              value={enabled ? product?.term : 0}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
            />
            <i>month(s)</i>
          </div>
        )}
        {lienHolderName && (
          <div className="fees_and_deductions--row-provider margin-left">
            <input
              className="text-input"
              type="text"
              id="provider"
              placeholder="Lien holder"
              name={product?.key}
              onChange={e => onChange(e)}
              disabled={!enabled}
              value={enabled ? product?.name : ''}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
            />
          </div>
        )}

        <div
          className={`fees_and_deductions--row-value ${requireName || lienHolderName || termField ? '' : 'margin-left'}`}>
          <i>$</i>
          {hardValue ? (
            <input
              className="text-input"
              type="number"
              name={product?.key}
              onChange={e => onChange(e)}
              disabled={true}
              value={hardValue}
              placeholder={hardValue}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
            />
          ) : (
            <input
              className="text-input"
              type="number"
              placeholder="0"
              name={product?.key}
              onChange={e => onChange(e)}
              disabled={!enabled}
              value={enabled ? product?.value : 0}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
            />
          )}
        </div>
      </div>
      {subtext && <div className="fees_and_deductions--row-subtext">{subtext}</div>}
    </>
  );
};

export default FDRow;
