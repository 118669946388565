import { DealInformation, KVPair } from '@luna-protocol/core/src/types.ts';

import { OrderSummary, ProductItem, SerialNumber } from '../types.ts';
import { getSerialNumberItemTitle } from '../components/AmendDetails/getProducts.tsx';

const dealReducer = (
  deal: Partial<DealInformation>,
  serialNumbers: OrderSummary | undefined,
): Partial<DealInformation> => {
  const sellerPurchasePrice =
    serialNumbers?.serial_numbers?.reduce((acc, curr) => acc + Number(curr.nada_msrp_invoice), 0) || 0;

  const baseProductTotal = serialNumbers
    ? serialNumbers?.serial_numbers
        ?.map((product: SerialNumber, i) => {
          const PriceTitle = getSerialNumberItemTitle(product.serial_number_type, 'Price');
          return {
            title: PriceTitle,
            value: product.product_price?.toFixed(2),
            type: product.serial_number_type + '_' + i,
          };
        })
        .filter((product: ProductItem) => product.title.includes('Price'))
        .reduce((acc: number, obj: ProductItem) => {
          return acc + Number(obj.value);
        }, 0)
    : 0;

  let frontendLTVLimit = deal.totals?.maxFrontEndPercentUsedProduct || 0;
  if (
    serialNumbers?.serial_numbers[0]?.condition === 'new' &&
    deal.totals?.maxFrontEndPercentNewProduct !== undefined
  ) {
    frontendLTVLimit = deal.totals?.maxFrontEndPercentNewProduct;
  }

  let backendLTVLimit = deal.totals?.maxBackendPercentUsedProduct || 0;
  if (serialNumbers?.serial_numbers[0]?.condition === 'new' && deal.totals?.maxBackendPercentNewProduct !== undefined) {
    backendLTVLimit = deal.totals?.maxBackendPercentNewProduct;
  }

  let minimumBackendPriceLimit = deal.totals?.maxBackendAmountUsedProduct || 0;
  if (serialNumbers?.serial_numbers[0]?.condition === 'new' && deal.totals?.maxBackendAmountNewProduct !== undefined) {
    minimumBackendPriceLimit = deal.totals?.maxBackendAmountNewProduct;
  }

  let backendPriceLimit = minimumBackendPriceLimit || 0;

  if (
    serialNumbers?.serial_numbers[0]?.product_price &&
    serialNumbers?.serial_numbers[0]?.product_price * (deal.totals?.maxBackendAmountUsedProduct || 0) >
      (deal.totals?.maxBackendAmountUsedProduct || 0)
  ) {
    backendPriceLimit =
      serialNumbers?.serial_numbers[0]?.product_price * (deal.totals?.maxBackendAmountUsedProduct || 0);
  }

  if (serialNumbers?.serial_numbers[0]?.condition === 'new') {
    if (
      serialNumbers?.serial_numbers[0]?.product_price &&
      serialNumbers?.serial_numbers[0]?.product_price * (deal.totals?.maxBackendAmountNewProduct || 0) >
        (deal.totals?.maxBackendAmountNewProduct || 0)
    ) {
      backendPriceLimit =
        serialNumbers?.serial_numbers[0]?.product_price * (deal.totals?.maxBackendAmountUsedProduct || 0);
    }
  }

  if (backendPriceLimit > (deal.totals?.maxBackendOverall || 0)) {
    backendPriceLimit = deal.totals?.maxBackendOverall || 0;
  }

  const financiableTotals =
    deal.accessories?.filter(acc => acc.is_financeable)?.reduce((acc, curr) => acc + Number(curr.price), 0) || 0;

  const base_accessories = deal?.accessories?.reduce((acc, curr) => {
    return acc + (!curr.is_financeable ? Number(curr.price) : 0);
  }, 0);

  let backend_products = 0;
  if (deal.backend_products) {
    backend_products = deal.backend_products.reduce((acc: number, curr: KVPair) => {
      return acc + (typeof curr.value === 'number' ? curr.value : 0);
    }, 0);
  }

  const additions = deal?.additions?.reduce((acc: number, curr: KVPair) => {
    return acc + (typeof curr.value === 'number' ? curr.value : 0);
  }, 0);

  const deductions = deal?.deductions?.reduce((acc: number, curr: KVPair) => {
    return acc + (typeof curr.value === 'number' ? curr.value : 0);
  }, 0);

  // base price is the product price plus the financiable accessories
  // base price and base product price are the same (baseProductTotal + financiableTotals)
  // price of of the unit sold for + price of the accessories ( financiable and non-financiable )
  const basePrice = financiableTotals + baseProductTotal;

  let frontendTotalValue = 0;
  if (serialNumbers?.serial_numbers[0]?.condition === 'new') {
    frontendTotalValue = (baseProductTotal || 0) + (financiableTotals || 0) + (additions || 0) - (deductions || 0);
  } else {
    frontendTotalValue = (baseProductTotal || 0) + (additions || 0) - (deductions || 0);
  }

  const frontendLtvPercentage = isFinite(frontendTotalValue / sellerPurchasePrice)
    ? (frontendTotalValue / sellerPurchasePrice) * 100
    : 0;
  const backendLtvPercentage =
    backend_products && isFinite(backend_products / sellerPurchasePrice)
      ? (backend_products / sellerPurchasePrice) * 100
      : 0;

  let isBackendLTVExceed = false;
  if (backend_products > minimumBackendPriceLimit) {
    isBackendLTVExceed = backendLtvPercentage > backendLTVLimit;
  }

  return {
    ...deal,
    totals: {
      ...deal.totals,
      base_accessories,
      additions,
      backend_products,
      deductions,
      total_requested:
        (baseProductTotal || 0) +
        (financiableTotals || 0) +
        (additions || 0) /* tax and fee */ +
        (backend_products || 0) -
        (deductions || 0),
      isFrontendLTVExceed: frontendLTVLimit < frontendLtvPercentage,
      isBackendLTVExceed,
      isBackendValueExceed: backendPriceLimit < backend_products,
      backendPriceLimit,
      frontendTotalValue,
      frontendLtvPercentage,
      backendLtvPercentage,
      basePrice,
      base_product_total: serialNumbers?.serial_numbers?.reduce((acc: number, curr: SerialNumber) => {
        return acc + (curr.product_price || 0);
      }, 0),
    },
  };
};

export default dealReducer;
