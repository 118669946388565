import { useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import getBaseApi from './getBaseApi';

const useRemoveSessionOnLeave = () => {
  const location = useLocation();

  const shouldRemoveSessionRef = useRef(true);

  const resetSessionRemoval = () => {
    shouldRemoveSessionRef.current = false;
  };

  useEffect(() => {
    const onLeave = () => {
      if (shouldRemoveSessionRef.current) {
        axios.post(`${getBaseApi()}/auth/signout`, null, {
          headers: { Authorization: `Bearer ${localStorage.getItem('user_token')}` },
        });
        localStorage.removeItem('user_token');
      }
    };

    const handleBeforeUnload = () => {
      onLeave();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      onLeave();
    };
  }, [location]);

  return { resetSessionRemoval };
};

export default useRemoveSessionOnLeave;
